import React, { useRef, useEffect, useState } from "react";

import { v1 as uuid } from "uuid";
import io from "socket.io-client";
import logo from './assets/logoaeat.svg';
import loading from './assets/loadingaeat.gif';
import publicIp from "public-ip";

export const getClientIp = async () => await publicIp.v4({
    fallbackUrls: [ "https://ifconfig.co/ip" ]
});

const CreateRoom = (props) => {

    //Genera el ID único de emisión/recepción
    var id = uuid();

    //Declaración objetos ref
    const partnerVideo = useRef();
    const userStream = useRef();
    const userVideo = useRef();
    const peerRef = useRef();
    const socketRef = useRef();
    const otherUser = useRef();
    var peer;
    var isSafari;
    // const [connected, setConnected] = useState(false);
    const publicIp = require('public-ip');
    var IPPublica;
    
    (async () => {
        IPPublica = await publicIp.v4();
        console.log(IPPublica);
    })();
    


    //Url que recibe el cliente
    const url = window.location.origin + "/room/" + id;
    
    //Mensaje que recibe el cliente
    const mensaje = "Entra en el enlace para la videollamada con AEAT. Caduca en 5 minutos: " + url;

    //Variables globales
    var video;
    var context;
    var canvas;
    var myWidth;
    var myHeight;
    var ratio;

    //Equivalente en cierta forma a ComponentDidMount, se ejecuta tras generar los componentes, establece los sockets con el id generado anteriormente
    useEffect(() => {
        if(props.match.params.aleatorio != "xVcT0Isf"){
            window.location = 'https://www.agenciatributaria.es'; 
            return null;
        }
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
            userVideo.current.srcObject = stream;
            userStream.current = stream;

            socketRef.current = io.connect("/");
            socketRef.current.emit("join room", id);

            socketRef.current.on('other user', userID => {
                callUser(userID);
                otherUser.current = userID;
            });

            socketRef.current.on("user joined", userID => {
                otherUser.current = userID;
            });

            socketRef.current.on("offer", handleRecieveCall);

            socketRef.current.on("answer", handleAnswer);

            socketRef.current.on("ice-candidate", handleNewICECandidateMsg);
        }).catch(function(err) {
            alert("Se necesita disponer de al menos, un micrófono y permitir al navegador su acceso. Vuelve a intentarlo.");
        });;

        //Recoge los componentes de video y canvas
        video = document.getElementById('videmisor');
        canvas = document.querySelector('canvas');
        context = canvas.getContext('2d');
        canvas.style.display = "none";

        //Listener del video. Establece las variables de resolución de imágenes, si es un vídeo
        //con resolución horizontal mayor que vertical, la devuelve como 640x480, si es mas vertical
        //que horizontal, le deja la resolución que le llega
        video.addEventListener('loadedmetadata', function(){
            ratio = video.videoWidth/video.videoHeight;
            if(video.videoWidth > video.videoHeight){
                myWidth = 640;
                myHeight = 480;
                canvas.width = 640;
                canvas.height = 480;
            }else{
                myWidth = video.videoWidth;
                myHeight = video.videoHeight;
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
            }
        }, false);

    }, []);

    //Llamada al otro usuario
    function callUser(userID) {
        console.log("callUser");

        peerRef.current = createPeer(userID);
        userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
    }

    //Establece conexión RTCPeer con los TURN y STUN de XIRSYS en este cso
    function createPeer(userID) {
        peer = new RTCPeerConnection({
            iceServers: [{
                urls: ["stun:eu-turn3.xirsys.com"]
            }, {
                username: "hXJOMNBJpQFkbQPCQgfzhLeKeO3Pv_XtnMc3WKdVCLtLSruS-NqFbFmjb7e9jkKxAAAAAGCvdN1zZXJ2aW5mb3Jt",
                credential: "9d6d699a-bed6-11eb-bb39-0242ac140004",
                urls: [
                    "turn:eu-turn3.xirsys.com:80?transport=udp",
                    "turn:eu-turn3.xirsys.com:3478?transport=udp",
                    "turn:eu-turn3.xirsys.com:80?transport=tcp",
                    "turn:eu-turn3.xirsys.com:3478?transport=tcp",
                    "turns:eu-turn3.xirsys.com:443?transport=tcp",
                    "turns:eu-turn3.xirsys.com:5349?transport=tcp"
                ]
            }]
        });

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);
        
        fetch('https://aeat.servinformcrm.com/logcreate.php',{
            method: 'POST',
            headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                }),
            body: "datos=" + IPPublica.toString() + "&funcion=videollamada"
        });

        return peer;
    }

    //Negociación entre peers
    function handleNegotiationNeededEvent(userID) {
        peerRef.current.createOffer().then(offer => {
            return peerRef.current.setLocalDescription(offer);
        }).then(() => {
            const payload = {
                target: userID,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            };
            socketRef.current.emit("offer", payload);
        }).catch(e => console.log(e));
    }

    //Espera que el cliente RTC acepte la llamada (en este caso, de forma automática) mandándole un mensaje
    function handleRecieveCall(incoming) {
        peerRef.current = createPeer();
        const desc = new RTCSessionDescription(incoming.sdp);
        peerRef.current.setRemoteDescription(desc).then(() => {
            userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
        }).then(() => {
            return peerRef.current.createAnswer();
        }).then(answer => {
            return peerRef.current.setLocalDescription(answer);
        }).then(() => {
            const payload = {
                target: incoming.caller,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            }
            socketRef.current.emit("answer", payload);
        })
    }

    //Acepta la llamada RTC del otro (recibe un mensaje)
    function handleAnswer(message) {
        const desc = new RTCSessionDescription(message.sdp);
        peerRef.current.setRemoteDescription(desc).catch(e => console.log(e));
    }

    function handleICECandidateEvent(e) {
        if (e.candidate) {
            const payload = {
                target: otherUser.current,
                candidate: e.candidate,
            }
            socketRef.current.emit("ice-candidate", payload);
        }
    }

    function handleNewICECandidateMsg(incoming) {
        const candidate = new RTCIceCandidate(incoming);

        peerRef.current.addIceCandidate(candidate)
            .catch(e => console.log(e));
    }

    //Le indica que partner video, el video del cliente, es el objeto e.streams[0]
    function handleTrackEvent(e) {
        partnerVideo.current.srcObject = e.streams[0];
    };

    //Hace una captura del vídeo del cliente
    function hacerCaptura(){  
        //Se rellena el contexto del canvas con anchura, altura y el vídeo.
        context.fillRect(0,0,myWidth, myHeight);
        context.drawImage(video, 0, 0, myWidth, myHeight);

        //Indica de qué tipo es el dato del canvas y crea un enlace.
        var url = canvas.toDataURL("image/png");
        var link = document.createElement('a');

        //Obtenemos la fecha y hora para el nombre. Si mes o día son inferiores a 10, les pone un 0 delante.
        var today = new Date();
        var mes;
        var dia;
        var mesactual = today.getMonth() + 1;
        if(mesactual < 10){
            mes = '0' + mesactual;
        }else{
            mes = today.getMonth() + 1;
        }
        if(today.getDate() < 10){
            dia = '0' + today.getDate();
        }else{
            dia = today.getDate();
        }
        var date = today.getFullYear() + '' + mes+ '' + dia;
        var time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();

        //Indica que va a descargar el link creado antes con el nombre compuesto por fecha y hora. Lo pulsa para iniciar la descarga.
        link.download = date + '' + time + '.png';
        link.href = url;
        link.click();
    };

    function peerClos(){
        peer.close();
        peerRef.close();
    }

    //Detecta si se navega desde safari para mostrar controles o no
    isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

        return (
            <table width="100%" class="tablacentro">
                <tr>
                    <td>
                        <img width="360" class="logoaeat" height="121" src={logo}></img><br></br>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        {isSafari ? <video id="vidorigen" width="512" height="384" muted controls autoPlay ref={userVideo} playsInline /> : <video id="vidorigen" width="512" muted height="384" autoPlay ref={userVideo} playsInline /> }
                        {isSafari ? <video id="videmisor" width="512" height="384" controls autoPlay ref={partnerVideo} poster={loading} playsInline /> : <video id="videmisor" width="512" height="384" autoPlay ref={partnerVideo} poster={loading} playsInline /> } <br></br>
                        <button onClick={hacerCaptura} class="btn btn-primary" id="botcaptura">
                            <span class="material-icons" id="spancam">
                                local_see
                            </span>
                            &nbsp; Captura
                        </button>
                        <a onClick={peerClos} href="xVcT0Isf" class="btn btn-danger">
                            <span class="material-icons" id="spancam">
                                phone_disabled
                            </span>
                            &nbsp; Colgar
                        </a>
                        {/* {connected ? <span>Conectado</span> : <span>Desconectado</span>} */}
                        <canvas id="canvas" width="640" height="480"></canvas>
                    </td>
                    <td>
                        <form method="POST" action="https://aeat.servinformcrm.com/controller.php" target="_blank" name="formdata">
                            <label align="right">Enviar enlace al cliente:</label>
                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                home
                                            </span>
                                        </div>
                                    </div>
                                    <input name="from" required type="text" class="form-control" placeholder="Remitente" value="AEAT"/>
                                </div>
                            </div>
    
                            <br/>
    
                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                call
                                            </span>
                                        </div>
                                    </div>
                                    <input name="tel" type="tel" class="form-control" placeholder="Teléfono"/>
                                </div>
                            </div>
    
                            <br/>

                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                alternate_email
                                            </span>
                                        </div>
                                    </div>
                                    <input type="email" class="form-control" name="email" id="emailval" placeholder="Correo electrónico"/>
                                </div>
                            </div>
    
                            <br/>
    
                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                email
                                            </span>
                                        </div>
                                    </div>
                                    <textarea name="msg" required placeholder="Mensaje" class="form-control" value={mensaje} rows="5"></textarea>
                                </div>
                            </div>
    
                            <br/>
    
                            <input name="pass" type="hidden" value="1234!Z"/>
                            <input name="url" type="hidden" value={url}/>
                            <input name="idllamada" type="hidden" value={id}/>

                            <br/>
    
                            <button type="submit" name="bot_sms" class="btn btn-info">Enviar SMS</button>

                            <button type="submit" name="bot_email" class="btn btn-primary">Enviar correo electrónico</button>
                        </form>
                        {/* <form method="POST" action="https://editajob.com/sms/index.php" target="_blank">
                            <input value="admin1234" type="hidden" name="pass"></input>
                            <input value={id} type="hidden" name="url"></input>
                        <button type="submit" class="btn btn-success" >Enviar</button>
                        </form> */}
                    </td>
                </tr>
            </table>
        );
}

export default CreateRoom;
